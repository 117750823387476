<template>
  <a-modal
    title="添加视频分类"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <!-- 礼物名称 -->
            <a-form-item label="视频分类" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input 
                placeholder="请输入"
                v-decorator="['name', { rules: [{ required: true, message: '请输入！' }] }]"
                :max-length='5'
              />
            </a-form-item>
            <!-- 产币 -->
            <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number 
                placeholder="请输入" 
                :min="1"
                :max="1000"
                style="width: 40%;min-width: 100px"
                v-decorator="['sort', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { videoClassAdd } from '@/api/modular/mallLiving/videoClass'

export default {
  data() {
    return {
      loading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this)
    }
  },

  methods: {
    // 初始化方法
    add() {
      this.visible = true
    },
    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
            videoClassAdd(values).then((res) => {
              if(res.success){
                this.$message.success("新增成功！")
                this.$emit("ok",values)
                this.confirmLoading = false
                this.handleCancel()
              }else{
                setTimeout(()=>{
                  this.confirmLoading = false
                }, 600)
              }
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    }
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>